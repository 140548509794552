

import TextField from "@atlaskit/textfield";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import moment from "moment";

export default function InsuredDetails(props: any) {
    console.log(props)
    return (
        <>
            <div className="row  my-2 mx-auto">
                <div className="col-12">
                    <span className="text-14 ">
                        <b>Insured Information</b>
                    </span>
                </div>
            </div>
            <div className="row smartscan-block  mx-auto ">
                <div
                    className="form-group col-lg-4 col-md-4 col-sm-12 mb-2"
                >
                    <label>Insured Name</label>
                    <span className="text-danger ">*</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.insuredName}
                        placeholder="Enter first name"
                    />
                </div>
                <div
                    className="form-group col-lg-4 col-md-4 col-sm-12 mb-2"
                >
                    <label>Address</label>
                    <span className="text-danger ">*</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.address}
                        placeholder="Enter address"
                    />
                </div>
                <div
                    className="form-group col-lg-4 col-md-4 col-sm-12 mb-2"
                >
                    <label>City</label>
                    <span className="text-danger ">*</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.city}
                        placeholder="Enter city"
                    />
                </div>
                <div
                    className="form-group col-lg-4 col-md-4 col-sm-12 mb-2"
                >
                    <label>State</label>
                    <span className="text-danger ">*</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.state}
                        placeholder="Enter state"
                    />
                </div>
                <div
                    className="form-group col-lg-4 col-md-4 col-sm-12 mb-2"
                >
                    <label>ZipCode</label>
                    <span className="text-danger ">*</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.zipCode}
                        placeholder="Enter zip code"
                    />
                </div>
            </div>

            <div className="row  my-2 mx-auto">
                <div className="col-12">
                    <span className="text-14 ">
                        <b>Policy Information</b>
                    </span>
                </div>
            </div>
            <div className="row smartscan-block  mx-auto ">
                <div
                    className="form-group col-lg-4 col-md-4 col-sm-12 mb-2"
                >
                    <label>Policy Number</label>
                    <span className="text-danger ">*</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.policyNumber}
                        placeholder="Enter policy number"

                    />
                </div>
                <div
                    className="form-group col-lg-4 col-md-4 col-sm-12 mb-2"
                >
                    <label>Policy premium</label>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.policyPremium}
                        placeholder="Enter policy premium"
                    />
                </div>
                <div
                    className="form-group col-lg-4 col-md-4 col-sm-12 mb-2"
                >
                    <span>Effective Date</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.effectiveDate}
                         placeholder="Enter effective date"
                    />
                </div>
                <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Expiration Date</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.expirationDate}
                        placeholder="Enter expiration date"
                    />
                </div>
            </div>
        </>
    )
}