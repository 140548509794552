import React, { FC, useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DownloadIcon from "../../assets/images/icon _download_.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import moment from "moment";
import feedbackImg from "../../assets/images/feedback.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import { Dialog } from "primereact/dialog";
import CommonValues from "../common/utils";
import Header from "../common/Header";
import RabbitSignMiddleHeader from "./RabbitSignMiddleHeader";
import RabbitSignSidebar from "./RabbitSignSidebar";
import Footer from "../common/Footer";
import AtkLoader from "../AtlaskitControls/AtkLoader";

export default function RabbitSignHistory(props: any) {
    const navigate = useNavigate();
    const toast: any = useRef("");
    const [historyLoading, setHistoryLoading] = useState(true);
    const [rabbitSignHistoryData, setRabbitSignHistoryData] = useState<any>([]);


    useEffect(() => {
        getRabbitSignHistory();
      }, []);
    
      const getRabbitSignHistory = () => {
        setHistoryLoading(true);
        const token = CommonValues.GetToken();
        var config = {
          method: "get",
          url: `${process.env.REACT_APP_DataFabric_Url}/api/RabbitSign/history`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
    
        axios(config)
          .then((response: any) => {
            setRabbitSignHistoryData(response.data);
            setHistoryLoading(false);
          })
          .catch((error: any) => {
            console.log(error);
            if (error.response != null) {
              if (error.response.status === 401) {
                CommonValues.Logout(navigate);
              } else {
                toast.current.show({ severity: "error", detail: "Unknown error while getting rabbit sign history", life: 3000 });
              }
            }
            setHistoryLoading(false);
          });
      };

    return(<>
         <>
      <Toast ref={toast} />
      <div className="rabbitsign-history-main-page">
        <Header pagename="rabbitsign-history" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <RabbitSignMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <RabbitSignSidebar pagename="rabbitsign-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12 mt-2  text-end ">
                  </div>
                  <div className="col-12  history-table mt-3">
                    {historyLoading ?
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                        <AtkLoader />{" "}
                      </div> :
                      <DataTable
                        value={rabbitSignHistoryData}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        selectionMode="single"
                      >

                      </DataTable>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>

    </>)
}