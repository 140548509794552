import PFScanHistory from "./PFScanHistory";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import axios from "axios";
import CommonValues from "../../common/utils";
import { Toast } from "primereact/toast";
import PrimaryDetailsPage from "./PrimaryDetailsPage";
import PFScanMiddleHeader from "./PFScanMiddleHeader";
import PFScanSidebar from "./PFScanSidebar";

export default function () {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [showNewPFScan, setShowNewPFScan] = useState(true);
  const [showPFScanHistory, setShowPFScanHistory] = useState(false);
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("0");

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
    //getCardDetails();
  });

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCurrentBalance("" + response.data.currentBalance / 100);
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  return (
    <>
      <div className="pfscan-main-page">
        <Toast ref={toast} />
        <Header pagename="pfscan-landing-page" />
        <div className="content-wrapper">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <PFScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto ">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <PFScanSidebar pagename="pfscan-landing-page" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row   d-flex  align-items-end text-12 position-relative">
                  {showNewPFScan ? (
                    <div className="col-12 p-0">
                      <PrimaryDetailsPage />
                      {/* <PFOcrScan
                      submissionIdFromHistory={submissionIdFromHistory}
                      isSubmissionIdFromHistory={isSubmissionIdFromHistory}
                    /> */}
                    </div>
                  ) : null}
                  {showPFScanHistory ? (
                    <div className="col-12">
                      <PFScanHistory />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
