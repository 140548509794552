import React from 'react'
import CommissionLogo from "../../assets/images/GenerateCommission.svg";

export default function CommissionScanMiddleHeader() {
  return (
    <div className="row middle-header-row">
      <div className="col-12">
        <div className="middle-header-col active ms-2">
          <img src={CommissionLogo} className="" width="20%"/>
          <span className="middle-header-title">Commission Scan</span>
        </div>
      </div>{" "}
    </div>
  )
}
