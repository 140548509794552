import React from "react";
import HistoryIcon from "../../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../../assets/images/NewQuote_B 1.svg";

export default function FenrisSidebar(props: any) {
  const navigate = useNavigate();
  const onFenrisPrefillClick = () => {
    navigate("/fenrisprefill");
  };
  const onFenrisHistoryClick = () => {
    navigate("/fenrisprefillhistory");
  };
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename == "fenris-landing-page" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onFenrisPrefillClick}
        >
          <img src={NewIcon} className={props.pagename == "fenris-landing-page" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">New Prefill</span>{" "}
        </span>
        <span
          className={props.pagename == "fenris-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onFenrisHistoryClick}
        >
          <img src={HistoryIcon} className={props.pagename == "fenris-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
      </>
    </div>
  );
}
