
import React from "react";
import HistoryIcon from "../../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../../assets/images/newquote.png";
export default function MTMScanSidebar(props: any) {
  const navigate = useNavigate();
  
  const onNewMTMScanClick =()=>{
    navigate("/mtmscan");
  }
  
  const OnMTMScanHistoryClick=()=>{
    navigate("/mtmscanhistory");
  }
  
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename == "mtm-scan" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onNewMTMScanClick}
        >
          <img src={NewIcon} className={props.pagename == "mtm-scan" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title" >New MTM Scan</span>{" "}
        </span>

       
        <span
          className={props.pagename == "mtm-scan-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={OnMTMScanHistoryClick}
        >
          <img
            src={HistoryIcon}
            className={props.pagename == "mtm-scan-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "}
          />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
       
      </>
    </div>
  );
}

