import Header from "../../common/Header"
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import MTMScanSidebar from "./MTMScanSidebar";
import MTMScanMiddleHeader from "./MTMScanMiddleHeader";
import MTMScanFileUploadPage from "./MTMScanFileUploadPage";
export default function MTMScanLadingPage() {
    const toast: any = useRef()



    return (
        <>
            <Toast ref={toast} />
            <div className="smartscan-main-page">
                <Header pagename="smart-scan" />
                <div className="content-wrapper ">
                    <div className="row  wrapper-main-row mx-auto wrapper-below-div">
                        <div className="col-12">
                            <MTMScanMiddleHeader pagename="mtm-scan" />
                        </div>
                        <div className="row main-content-row mx-auto">
                            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                                <MTMScanSidebar pagename="mtm-scan" />
                            </div>
                            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                                 <MTMScanFileUploadPage/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}