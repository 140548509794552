import { ChangeEvent, useEffect, useRef, useState } from "react";
import PfdIcon from "../../assets/images/file.png";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import InsuredDetails from "./InsuredDetails";
import CoverageLimitDetails from "./CoverageLimitDetails";
import DriverScheduleDetails from "./DriverScheduleDetails";
import ScheduledVehiclesDetails from "./ScheduledVehiclesDetails";
import CommonValues from "../../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function MTMScanFileUploadPage() {
    const toast: any = useRef("");
    const navigate = useNavigate();
    const [uploadFile, setUploadFile] = useState<any>([]);
    const [uploadFileLimit, setUploadFileLimit] = useState(1);
    const [filesCount, setFilesCount] = useState(0);
    const [requestFileCount, setRequestFileCount] = useState("");
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [uploadingRetry, setUploadingRetry] = useState(false);
    const [isfileuploaded, setIsfileuploaded] = useState(false);
    const [fileNameTitle, setFileNameTitle] = useState("");
    const [fileName, setFileName] = useState("");
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [uploadPdfUrl, setUploadPdfUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("Insured");
    const [insuredName, setInsuredName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [effectiveDate,setEffectiveDate]=useState("");
    const [expirationDate,setExpirationDate]=useState("");
    const [policyNumber,setPolicyNumber]=useState("");
    const [policyPremium,setPolicyPremium]=useState("");
    const [coveragelimitResponse, setCoverageLimitResponse] = useState<any>([]);
    const [scheduledVehicleResponse, setScheduledVehicleResponse] = useState<any>([]);
    const [driverScheduleResponse, setDriverScheduleResponse] = useState<any>([]);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [showUploadAgain, setShowUploadAgain] = useState(false);
    const [ncURL,setNcURL]=useState("");
    const [submitLoading,setSubmitLoading]=useState(false);



    const handleTabClick = (tabName: any) => {
        setActiveTab(tabName);
    };

    const createUploadRequest = () => {
        setLoading(true);
        

        var FormData = require("form-data");
        var data = new FormData();
        data.append("FirstFormFile", uploadFile[0].file);
        data.append("Question", "extract all the records from given document")
        let token = CommonValues.GetToken();

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_MTMLLMEXTRACTOR}/process_files`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };
        axios(config)
            .then((response: any) => {
                let driverScheduleResponse:any=[];
                let coveragelimitResponse:any=[];
                let scheduledVehicleResponse:any=[];
                driverScheduleResponse=response.data[0].DriverSchedule;
                coveragelimitResponse.push(response.data[0].AutoMobileLiability);
                scheduledVehicleResponse=response.data[0].ScheduledVehicles.Vehicles
                setLoading(false);
                setIsFileUpload(true);
                setInsuredName(response.data[0].NamedInsured)
                setAddress(response.data[0].StreetAddress)
                setCity(response.data[0].City)
                setState(response.data[0].State)
                setZipCode(response.data[0].ZipCode)
                setEffectiveDate(moment(response.data[0].EffectiveDate).format("MM-DD-YYYY"))
                setExpirationDate(moment(response.data[0].ExpirationDate).format("MM-DD-YYYY"))
                setPolicyNumber(response.data[0].PolicyNumber)
                setPolicyPremium(response.data[0].PolicyPremium)
                setDriverScheduleResponse(driverScheduleResponse)
                setCoverageLimitResponse(coveragelimitResponse);
                setScheduledVehicleResponse(scheduledVehicleResponse)
            })
            .catch((error: any) => {
                console.log(error);
                localStorage.removeItem("currentPage");
                if (error.response != null && error.response.status == 401) {
                    navigate("/");
                    CommonValues.Logout(navigate);
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Error while creating the request.",
                        life: 3000,
                    });
                    setLoading(false);
                }
            });
    };
    const onFileSelectHandler = (files: any) => {
        setFileName("");
        setFileNameTitle("")
        let fileList = [];
        let totalSize = 0;
        let currentFiles = uploadFile;
        let filename = files[0].name;
        if (filename.length > 50) {
            let shortenString = filename.substring(0, 50).concat("...");
            setFileName(shortenString);
            setFileNameTitle(filename);
        } else {
            setFileName(filename);
            setFileNameTitle(filename);
        }
        for (let i = 0, len = files.length; i < len; i++) {
            fileList.push({
                file: files[i],
                name: files[i].name,
                size: files[i].size,
            });
            totalSize = totalSize + files[i].size;
        }

        if (fileList.length > 0) {
            if (currentFiles.length > 0) {
                for (var i = 0, l = currentFiles.length; i < l; i++) {
                    for (var j = 0; j < fileList.length; j++) {
                        if (currentFiles[i].name === fileList[j].name) {
                            fileList.splice(j, 1);
                        }
                    }
                }
            }
            let allFiles = currentFiles.concat(fileList);
            if (allFiles.length > uploadFileLimit) {
                allFiles = allFiles.slice(0, uploadFileLimit);
                toast.current.show({
                    severity: "error",
                    detail: "Multiple files cannot be uploaded at the same time; please upload a single file.",
                    life: 3000,
                });
            }
            setUploadFile(allFiles);
            setFilesCount(allFiles.length);
            setRequestFileCount(allFiles.length);
            setFilesCount(allFiles.length);
        }
    };

    const removeFileFromList = () => {
        setUploadFile([]);
        setIsfileuploaded(false);
    };
    const uploadAgain = () => {
        setActiveTab("Insured");
        setIsFileUpload(false);
        setUploadFile([]);
        setFileName("");
        setFileNameTitle("");
        setShowUploadAgain(false);
        setInsuredName("");
        setAddress("");
        setCity("");
        setState("");
        setZipCode("");
        setPolicyNumber("");
        setPolicyPremium("");
        setEffectiveDate("");
        setExpirationDate("");
        setCoverageLimitResponse([]);
        setDriverScheduleResponse([]);
        setScheduledVehicleResponse([]);
    };

    const  onSubmit =()=>{
        const insuredDatabaseId: any = createNewInsured();
    }
    const createNewInsured = async () => {
        setSubmitLoading(true)
        setShowUploadAgain(true);
        setNcURL("");
        try {
          var token = CommonValues.GetToken();
          var ncToken = CommonValues.GetNCToken();
          var data = JSON.stringify({
            commercialName: insuredName,
            firstName: "",
            lastName: "",
            dba: "",
            addressLine1: address,
            addressLine2: "",
            state: state,
            city: city,
            zipCode: zipCode,
            eMail: "",
            cellPhone: "",
            fein: "",
          });
          var config = {
            method: "post",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insertinsured?submissionId=${"hdtgfy-474dofi-854754f-ufifu"}&nowCertsToken=${ncToken}`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
    
          const response = await axios(config);
          let insuredInfo = response.data.database_id;
          setNcURL(response.data.newInsuredLunchUrl);
          setShowUploadAgain(true);
          setSubmitLoading(false)
          toast.current.show({
            severity: "success",
            detail: "Insured has been created successfully in NowCerts",
            life: 3000,
          });
          return insuredInfo;
        } catch (error: any) {
          setSubmitLoading(false)
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 400) {
              toast.current.show({
                severity: "error",
                detail: error.response.errors,
                life: 3000,
              });
            } else {
              toast.current.show({
                severity: "error",
                detail: "Please correct NowCerts username and password.",
                life: 3000,
              });
            }
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while creating insured inside the NowCerts.",
              life: 3000,
            });
          }
          let insuredInfo = "";
          return insuredInfo;
        }
      };

    const onChangeEffectiveDate = (value: any) => {
        setEffectiveDate(value);
      };
      const onChangeExpiryDate = (value: any) => {
        setExpirationDate(value)
      };
    return (
        <>
            <Toast ref={toast} />
            <div className="col-md-8 d-flex  align-items-center">
            </div>
            <div className="col-12">
                <div className="row m-3">
                    {isFileUpload ? (
                        <div className="col">
                           {/* <span>Test</span> */}
                        </div>
                    ) : (
                        <>
                            <div
                                className="col uploadNowcertsDoc25 me-3 "
                                // onDrop={(e) => {
                                //   onDropHandler(e);
                                // }}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <div className="row w-100 ">
                                    {uploadFile.length > 0 ? (
                                        <div className="col-12">
                                            {uploadFile.map((file: any, index: any) => (
                                                <div className="show-pdf-icon">
                                                    <div className="scan-file-name">
                                                        <div className="preview-icon">
                                                            <img src={PreviewIcon} />
                                                        </div>
                                                        <div>
                                                            <div title={fileNameTitle}>{fileName}</div>
                                                            <div>
                                                                {(parseInt(file.size) / 1000).toFixed(0)}
                                                                KB
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {uploadComplete ? (
                                                        <div>
                                                            <span className="uploaded">Uploaded</span>
                                                        </div>
                                                    ) : (
                                                        uploadingFiles == false && (
                                                            <div onClick={() => removeFileFromList()} className="show-cursor">
                                                                <img src={RemoveIcon} />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <>
                                            <>
                                                <div className="row" style={{ textAlign: "center" }}>
                                                    <div className="col-12">
                                                        <img src={PfdIcon} alt="" width="70" />
                                                    </div>

                                                    <div className="col-12 mt-4">
                                                        <div className="col-12">
                                                            <span className="drag-and-drop">Drag & Drop file here</span>
                                                        </div>
                                                        <div className="col-12 my-2">
                                                            <span
                                                                style={{
                                                                    color: "black",
                                                                    fontSize: "large",
                                                                }}
                                                            >
                                                                or
                                                            </span>
                                                        </div>
                                                        <label className="mb-0 show-cursor">
                                                            <span className="browse">Browse PDF</span>
                                                            <input
                                                                type="file"
                                                                name="filefield"
                                                                accept=".pdf"
                                                                id="bulkFileUploader"
                                                                style={{
                                                                    visibility: "hidden",
                                                                    display: "none",
                                                                }}
                                                                onChange={(e) => {
                                                                    onFileSelectHandler(e.target.files);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        </>
                                    )}

                                    <div className="col-12 text-center">
                                        {isfileuploaded ? null : (
                                            <>
                                                {uploadingFiles == false &&
                                                    uploadingRetry == false &&
                                                    uploadFile.length > 0 && (
                                                        <div
                                                            className="d-flex justify-content-center"
                                                            style={{
                                                                width: "100%",
                                                                padding: "20px",
                                                            }}
                                                        >
                                                            {loading == false ? (
                                                                <>
                                                                    <AtkButtonIconBefore
                                                                        label="Upload"
                                                                        className="pf-secondary-btn"
                                                                        onClick={() => {
                                                                            createUploadRequest();
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <div className="d-flex justify-content-center align-items-center p-2">
                                                                    <AtkLoader></AtkLoader>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </>)}


                    <div
                        className="col p-2 ms-2"
                        style={{
                            border: "1px solid darkgrey",
                            borderRadius: "30px",
                            minHeight: "66vh",
                        }}
                    >
                        <div className="row p-0 d-flex align-items-center mx-auto smartscan-tab">
                            <div className="col-12 d-flex align-items-center justify-content-start">
                                <span
                                    className={activeTab === "Insured" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                    onClick={() => {
                                        handleTabClick("Insured");
                                    }}
                                >

                                    Insured Details
                                </span>
                                <span
                                    className={activeTab === "CoverageLimits" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                    onClick={() => {
                                        handleTabClick("CoverageLimits");
                                    }}
                                >
                                    Coverage Limits
                                </span>
                                <span
                                    className={activeTab === "DriverSchedule" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                    onClick={() => {
                                        handleTabClick("DriverSchedule");
                                    }}
                                >
                                    Driver Schedule
                                </span>
                                <span
                                    className={activeTab === "ScheduleVehicles" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                                    onClick={() => {
                                        handleTabClick("ScheduleVehicles");
                                    }}
                                >
                                    Schedule Vehicles
                                </span>
                            </div>
                        </div>
                        {activeTab === "Insured" ? (
                            <InsuredDetails
                                insuredName={insuredName}
                                address={address}
                                city={city}
                                state={state}
                                zipCode={zipCode}
                                policyNumber={policyNumber}
                                policyPremium={policyPremium}
                                effectiveDate={effectiveDate}
                                expirationDate={expirationDate}
                                onChangeEffectiveDate={onChangeEffectiveDate}
                                onChangeExpiryDate={onChangeExpiryDate}
                            />

                        ) : null}
                        {activeTab === "CoverageLimits" ? (
                            <CoverageLimitDetails
                                coveragelimitResponse={coveragelimitResponse}
                            />

                        ) : null}
                        {activeTab === "DriverSchedule" ? (
                            <DriverScheduleDetails
                                driverScheduleResponse={driverScheduleResponse}
                            />

                        ) : null}
                        {activeTab === "ScheduleVehicles" ? (
                            <ScheduledVehiclesDetails
                                scheduledVehicleResponse={scheduledVehicleResponse}
                            />
                        ) : null}

                        {isFileUpload ?
                            <div className="row">
                                <div className="col-12 d-flex align-items-center  justify-content-end my-2">
                                {submitLoading ?   <div className="d-flex justify-content-center align-items-center">
                                      <AtkLoader />
                                    </div> : <>
                                {showUploadAgain ? (
                                     <AtkButtonIconBefore
                                     label="Upload Again"
                                     onClick={uploadAgain}
                                     className="pf-secondary-btn w-auto me-2 "
                                     style={{ marginTop: "7px" }}
                                 /> ):
                                    <AtkButton label="Submit" onClick={onSubmit} className="pf-secondary-btn w-auto me-2" />
                                }
                                </>
                            }
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        </>
    )
}