import { useNavigate } from "react-router-dom";
import React, { FC, useEffect, useState, useRef } from "react";
import CommonValues from "../../common/utils";
import Header from "../../common/Header";
import NowCertLogo from "../../assets/images/NowCerts.png";
import PfdIcon from "../../assets/images/file.png";
import ScanIcon from "../../assets/images/scan.png";
import HistoryIcon from "../../assets/images/History_B.svg";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import axios from "axios";
import CommissionScanHistory from "./CommissionScanHistory";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import { Skeleton } from "primereact/skeleton";
import SectionMessage from "@atlaskit/section-message";
import CommissionScanMiddleHeader from "../CommissionScan/CommissionScanMiddleHeader";
import CommissionScanSidebar from "../CommissionScan/CommissionScanSidebar";
import { OverlayPanel } from "primereact/overlaypanel";
import AtkButton from "../../AtlaskitControls/AtkButton";
import { Toast } from "primereact/toast";
import Footer from "../../common/Footer";
export default function CommissionScan() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [selectedtab, setSelectedTab] = useState(1);
  const [showHistory, setShowHistory] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [uploadFileLimit, setUploadFileLimit] = useState(1);
  const [fileNameTitle, setFileNameTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [insufficientBalance, setInsufficientBalance] = useState(true);
  const [transactionId, setTransactionId] = useState("");
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getCardDetails();
  }, []);

  const onHistoryClick = () => {
    navigate("/commissionscanhistory");
  };
  const onDropHandler = (event: any) => {
    event.preventDefault();
    onFileSelectHandler(event.dataTransfer.files);
  };
  const onFileSelectHandler = (files: any) => {
    setFileName("");
    setFileNameTitle("");
    const isValidated = validateSelectedFiles(files);
    if (!isValidated) {
      setErrorMessage("Please select PDF files only");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return false;
    }

    let fileList = [];
    let totalSize = 0;
    let currentFiles = uploadFile;
    let filename = files[0].name;
    if (filename.length > 50) {
      let shortenString = filename.substring(0, 50).concat("...");
      setFileName(shortenString);
      setFileNameTitle(filename);
    } else {
      setFileName(filename);
      setFileNameTitle(filename);
    }
    for (let i = 0, len = files.length; i < len; i++) {
      fileList.push({
        file: files[i],
        name: files[i].name,
        size: files[i].size,
      });
      totalSize = totalSize + files[i].size;
    }

    if (fileList.length > 0) {
      if (currentFiles.length > 0) {
        for (var i = 0, l = currentFiles.length; i < l; i++) {
          for (var j = 0; j < fileList.length; j++) {
            if (currentFiles[i].name === fileList[j].name) {
              fileList.splice(j, 1);
            }
          }
        }
      }
      let allFiles = currentFiles.concat(fileList);
      if (allFiles.length > uploadFileLimit) {
        allFiles = allFiles.slice(0, uploadFileLimit);
        setErrorMessage("Multiple files cannot be uploaded at the same time; please upload a single file");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
      setUploadFile(allFiles);
    }
  };
  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (fileExtension?.[1].toLowerCase().includes("pdf")) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  const removeFileFromList = () => {
    setUploadFile([]);
    setSuccessMessage(false);
  };
  const createUploadRequest = () => {
    if (uploadFile.length == 0) {
      setTimeout(() => {
        setErrorMessage("Please select at least one file to upload");
      }, 3000);
      return;
    }
    setLoading(true);
    const token = CommonValues.GetToken();
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", uploadFile[0].file);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/ncaicommissionstatement/scan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        setTransactionId(response.data.responseJson.transactionId)
        setLoading(false);
        setUploadFile([]);
        setSuccessMessage(true);
        getCardDetails();
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          setErrorMessage("Error while creating the request.");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
          setLoading(false);
        }
      });
  };
  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCurrentBalance(response.data.currentBalance / 100);
          setInsufficientBalance(response.data.currentBalance / 100 <= 0);
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const onFeedbackClick = (e: any) => {
    feedbackRef.current?.toggle(e);
  }
  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };
  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };
  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      onSaveFeedback(rowData);
    }
  };
  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: transactionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAICommissionStatement/commissionfeedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };
  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: transactionId,
      IsLiked: rowData.isLiked,
      Feedback: feedbackVal
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAICommissionStatement/commissionratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Ratings Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };
  return (
    <>
    <Toast ref={toast} />
    <div className="commission-scan-main-page">
      <Header pagename="commission-scan" />
      <div className="content-wrapper">
        <div className="row  wrapper-main-row mx-auto wrapper-below-div">
          <div className="col-12">
            <CommissionScanMiddleHeader />
          </div>
          <div className="row main-content-row mx-auto ">
            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
              <CommissionScanSidebar pagename="commission-scan" />
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
              {/* {showHistory ? (
                <CommissionScanHistory />
              ) : (
                <> */}
              <div className="col-lg-12 col-md-12 col-sm-12 main-content-col">
                <div className="row client-search-row ">
                  <div className="col-12 d-flex align-items-center justify-content-end">
                    <div className="row w-100 d-flex align-items-center justify-content-end">
                      <div className="col-7"></div>

                      <div className="col-3 text-end">
                        <label>
                          <b>Current Balance</b>
                        </label>
                      </div>
                      <div className="col-2">
                        {currentBalanceLoading ? (
                          <div className="d-flex align-items-center  justify-content-center p-2 ">
                            <AtkLoader />
                          </div>
                        ) : (
                          <div className="input-group">
                            <span className="input-group-text">$</span>
                            <AtkTextField className="input-field form-control p-0" type="text" id="text" value={currentBalance} isDisabled={true} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <>
                {currentBalanceLoading ? (
                  <Skeleton className="uploadNowcertscoi my-3" />
                ) : (
                  <>
                    {insufficientBalance ? (
                      <div className="uploadNowcertscoi my-3">
                        <AtkMessage appearance="error" messageText="Oop's ! There are not enough funds to complete this transaction." />
                      </div>
                    ) : (
                      <>
                        {successMessage ? (
                          <div className="uploadNowcertscoi my-3">
                            <SectionMessage appearance="success">
                              <h3>File uploaded successfully!</h3>
                              <p className="text-12">
                                We are processing your file. Please check the{" "}
                                <b className="show-cursor" style={{ color: "#2dabc1" }} onClick={onHistoryClick}>
                                  History
                                </b>{" "}
                                tab to find out the file's current progress status and
                              </p>
                              <p className="text-12">
                                feel free to provide your{" "}
                                <b className="show-cursor" style={{ color: "#2dabc1" }} onClick={(e) => { onFeedbackClick(e) }}>
                                  Feedback here{" "}
                                </b>
                              </p>
                            </SectionMessage>
                            <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                              <div className="row mx-auto d-flex align-items-center mb-2">
                                <div className="col-12 feedback-header">
                                  <h4>Feedback</h4>
                                </div>
                                <div className={"form-group col-12 mb-2 text-12"}>
                                  <textarea
                                    name="feedback"
                                    onChange={(e) => onFeedbackChange(e.target.value)}
                                    value={feedBackData.feedback}
                                    className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                                    rows={4}
                                    placeholder="Enter your feedback here"
                                  />
                                  <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                                </div>
                                <div className="col-4 d-flex justify-content-evenly">
                                  <i
                                    className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
                                    aria-hidden="true"
                                    onClick={(e) => onThumbsUpClick(feedBackData)}
                                  ></i>
                                  <i
                                    className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
                                    aria-hidden="true"
                                    onClick={(e) => onThumbDownClick(feedBackData)}
                                  ></i>
                                </div>
                                <div className="col-4 text-end p-2 pe-0  ">
                                  <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                                </div>
                                <div className="col-4 text-end p-2  ">
                                  {feedbackLoading ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                      <AtkLoader />
                                    </div>
                                  ) : (
                                    <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
                                  )}
                                </div>
                              </div>
                            </OverlayPanel>
                          </div>

                        ) : (
                          <div
                            className="uploadNowcertscoi my-3"
                            onDrop={(e) => {
                              onDropHandler(e);
                            }}
                            onDragOver={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <div className="row w-100 ">
                              {uploadFile.length > 0 ? (
                                <div className="col-12">
                                  {uploadFile.map((file: any, index: any) => (
                                    <div className="show-pdf-icon">
                                      <div className="file-name">
                                        <div className="preview-icon">
                                          <img src={PreviewIcon} />
                                        </div>
                                        <div className="filename">
                                          <div title={fileNameTitle}>{fileName}</div>
                                          <div>{(parseInt(file.size) / 1000).toFixed(0)}KB</div>
                                        </div>
                                      </div>
                                      <div onClick={() => removeFileFromList()} className="show-cursor">
                                        <img src={RemoveIcon} title="Remove file" />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <>
                                  <>
                                    <div className="row" style={{ textAlign: "center" }}>
                                      <div className="col-12">
                                        <img src={PfdIcon} alt="" width="70" />
                                      </div>

                                      <div className="col-12 mt-4">
                                        <div className="col-12">
                                          <span className="drag-and-drop">Drag & Drop file here</span>
                                        </div>
                                        <div className="col-12 my-2">
                                          <span
                                            style={{
                                              color: "black",
                                              fontSize: "large",
                                            }}
                                          >
                                            or
                                          </span>
                                        </div>
                                        <label className="mb-0 show-cursor">
                                          <span className="browse">Browse PDF</span>
                                          <input
                                            type="file"
                                            name="filefield"
                                            accept=".pdf"
                                            id="bulkFileUploader"
                                            style={{ visibility: "hidden", display: "none" }}
                                            onChange={(e) => {
                                              onFileSelectHandler(e.target.files);
                                            }}
                                          />
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                </>
                              )}

                              <div className="col-12 text-center">
                                {uploadFile.length > 0 && (
                                  <div className="d-flex justify-content-center" style={{ width: "100%", padding: "20px" }}>
                                    {loading == false ? (
                                      <>
                                        <AtkButtonIconBefore
                                          label="Upload"
                                          className="pf-secondary-btn"
                                          onClick={() => {
                                            createUploadRequest();
                                          }}
                                          title="Upload file"
                                        />
                                      </>
                                    ) : (
                                      <div className="d-flex justify-content-center align-items-center p-2">
                                        <AtkLoader></AtkLoader>
                                      </div>
                                    )}
                                  </div>
                                )}
                                {errorMessage.length > 0 ? (
                                  <div
                                    className="d-flex justify-content-center Acord25-upload-file-error"
                                    style={{ width: "100%", padding: "20px 20px 0px" }}
                                  >
                                    <AtkMessage appearance="error" messageText={errorMessage} />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
              {/* </>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
}
