import React from "react";
import HistoryIcon from "../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../assets/images/NewQuote_B 1.svg";

export default function VehicleSidebar(props: any) {
  const navigate = useNavigate();
  const onVehiclePrefillClick = () => {
    navigate("/vehicleprefill");
  };
  const onVehicleHistoryClick = () => {
    navigate("/vehicleprefillhistory");
  };
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename == "vehicle-landing-page" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onVehiclePrefillClick}
        >
          <img src={NewIcon} className={props.pagename == "vehicle-landing-page" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">New Prefill</span>{" "}
        </span>
        <span
          className={props.pagename == "vehicle-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onVehicleHistoryClick}
        >
          <img src={HistoryIcon} className={props.pagename == "vehicle-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
      </>
    </div>
  );
}
