
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column";
export default function ScheduledVehiclesDetails(props:any) {
console.log(props.scheduledVehicleResponse)
    return (
      <>
         <>
      <div className="row">
      <div className="col-12  history-table p-3 rounded-bottom">
         
                <DataTable
                      value={props.scheduledVehicleResponse}
                      paginator
                      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                      rows={8}
                      selectionMode="single"
                    >
                       <Column header="VIN" field="VIN"></Column>
                       <Column header="Make" field="Make" ></Column>
                       <Column header="Year" field="Year" ></Column>
                      
                      </DataTable>
                  {/* <table className="  history-details-font ">
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        Value
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        Value
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        Value
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        Value
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        Value
                      </div>
                    </div>
                  </table> */}
              </div>
              </div>
      </>
      </>
    )
  }