import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import BackIcon from "../../assets/images/Back Button.svg";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import ExistingAndFenrisData from "./ExistingAndFenrisData";
import { Toast } from "primereact/toast";
import FenrisMiddleHeader from "./FenrisMiddleHeader";
import FenrisSidebar from "./FenrisSidebar";
import AtkButton from "../../AtlaskitControls/AtkButton";


export default function SearchNewInsuredPage(props: any) {
    const toast: any = useRef("");
    const navigate = useNavigate();
    const [currentBalance, setCurrentBalance] = useState("0");
    const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
    const [clientName, setClientName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [cityName, setCityName] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [insuredId, setInsuredId] = useState("");
    const [showExistingAndFenrisData, setShowExistingAndFenrisData] = useState(false);
    const [currentBalanceUpdateFlag, setCurrentBalanceUpdateFlag] = useState(false);
    const [enrichDataFlag, setEnrichDataFlag] = useState(false);
    const [enrichButtonLoading,setEnrichButtonLoading]=useState(false);

    useEffect(() => {
        const token = CommonValues.GetToken();
        if (token == "" || token == null) {
            navigate("/");
            CommonValues.Logout(navigate);
        }
        getCardDetails();
    }, []);

    const onBackClick = () => {
        navigate("/fenrisprefill")
    }

    const getCardDetails = () => {
        setCurrentBalanceLoading(true);
        var token = CommonValues.GetToken();
        var config = {
            method: "get",
            url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
                if (response.data.success == true) {
                    setCurrentBalance("" + response.data.currentBalance / 100);
                }
                setCurrentBalanceLoading(false);
            })
            .catch((error) => {
                setCurrentBalanceLoading(false);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while getting current balance",
                            life: 3000,
                        });
                    }
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Unknown error while getting current balance",
                        life: 3000,
                    });
                }
            });
    };

    const onChangeClientName = (value: any) => {
        let inputValue = value.replace(/[^a-zA-Z ]/g, "");
        let Value = inputValue.slice(0, 255);
        setClientName(Value);
    }
    const onChangeAddressLine1 = (value: any) => {
        let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
        let Value = inputValue.slice(0, 255);
        setAddressLine1(Value);
    }
    const onChangeCity = (value: any) => {
        let Value = value.slice(0, 255);
        setCityName(Value);
    }
    const onChangeState = (value: any) => {
        setState(value)
    }
    const onChangeZipCode = (value: any) => {
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            if (value.length > 5) {
                return
            }
            setZipCode(value)
        }
    }

    const newInsuredCreate = async () => {
        try {
            setEnrichButtonLoading(true)
            var token = CommonValues.GetToken();
            var ncToken = CommonValues.GetNCToken();
            var data = JSON.stringify({
                commercialName: clientName,
                addressLine1: addressLine1,
                addressLine2: "",
                state: state,
                city: cityName,
                zipCode: zipCode,
            });
            var config = {
                method: "post",
                url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/createinsured?nowCertsToken=${ncToken}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                data: data,
            };

            const response = await axios(config);
            let insuredInfo = response.data.database_id;
            toast.current.show({
                severity: "success",
                detail: "Client has been created successfully in NowCerts",
                life: 3000,
            });
            setEnrichButtonLoading(false)
            return insuredInfo;
        } catch (error: any) {
            console.log(error);
            let errorMessage = "";
            if (error.response != null) {
                if (error.response.status == 401) {
                    navigate("/");
                    CommonValues.Logout(navigate);
                } else if (error.response.status == 400) {
                    errorMessage = error.response.errors;
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Please correct NowCerts username and password",
                        life: 3000,
                    });
                }
            } else {
                toast.current.show({
                    severity: "error",
                    detail: "Unknown error while creating client inside the NowCerts",
                    life: 3000,
                });
            }
            setEnrichButtonLoading(false)
            let insuredInfo = "";
            return insuredInfo;
        }
    };

    const onClickEnrichButton = async () => {
        if(checkValidation()){
        const insuredDatabaseId: any = await newInsuredCreate();
        setInsuredId(insuredDatabaseId);
        setEnrichDataFlag(!enrichDataFlag);
        setShowExistingAndFenrisData(true);
        }
    }

    const updateCurrentBalanceUpdateFlag = () => {
        setCurrentBalanceUpdateFlag(!currentBalanceUpdateFlag);
    };

    const balanceCheck = () => {
        getCardDetails();
    };

    const Allstate = [
        { name: "Alaska", code: "AK" },
        { name: "Alabama", code: "AL" },
        { name: "Arkansas", code: "AR" },
        { name: "Arizona", code: "AZ" },
        { name: "California", code: "CA" },
        { name: "Colorado", code: "CO" },
        { name: "Connecticut", code: "CT" },
        { name: "DistrictofColumbia", code: "DC" },
        { name: "Delaware", code: "DE" },
        { name: "Florida", code: "FL" },
        { name: "Georgia", code: "GA" },
        { name: "Hawaii", code: "HI" },
        { name: "Iowa", code: "IA" },
        { name: "Idaho", code: "ID" },
        { name: "Illinois", code: "IL" },
        { name: "Indiana", code: "IN" },
        { name: "Kansas", code: "KS" },
        { name: "Kentucky", code: "KY" },
        { name: "Louisiana", code: "LA" },
        { name: "Massachusetts", code: "MA" },
        { name: "Maryland", code: "MD" },
        { name: "Maine", code: "ME" },
        { name: "Michigan", code: "MI" },
        { name: "Minnesota", code: "MN" },
        { name: "Missouri", code: "MO" },
        { name: "Mississippi", code: "MS" },
        { name: "Montana", code: "MT" },
        { name: "NorthCarolina", code: "NC" },
        { name: "NorthDakota", code: "ND" },
        { name: "Nebraska", code: "NE" },
        { name: "NewHampshire", code: "NH" },
        { name: "NewJersey", code: "NJ" },
        { name: "NewMexico", code: "NM" },
        { name: "Nevada", code: "NV" },
        { name: "NewYork", code: "NY" },
        { name: "Ohio", code: "OH" },
        { name: "Oklahoma", code: "OK" },
        { name: "Oregon", code: "OR" },
        { name: "Pennsylvania", code: "PA" },
        { name: "RhodeIsland", code: "RI" },
        { name: "SouthCarolina", code: "SC" },
        { name: "SouthDakota", code: "SD" },
        { name: "Tennessee", code: "TN" },
        { name: "Texas", code: "TX" },
        { name: "Utah", code: "UT" },
        { name: "Virginia", code: "VA" },
        { name: "Vermont", code: "VT" },
        { name: "Washington", code: "WA" },
        { name: "Wisconsin", code: "WI" },
        { name: "WestVirginia", code: "WV" },
        { name: "Wyoming", code: "WY" },
    ];

    const checkValidation=()=>{
        let isValid=true;
        if(clientName ==null ||  clientName == undefined || clientName == ""){
            toast.current.show({
                severity: "error",
                detail: "Please enter client name.",
                life: 3000,
              });
              return isValid=false
        }else if(addressLine1 == null || addressLine1 == undefined || addressLine1 ==""){
            toast.current.show({
                severity: "error",
                detail: "Please enter address line1.",
                life: 3000,
              });
              return isValid=false
        }else if(cityName == null || cityName == undefined || cityName ==""){
            toast.current.show({
                severity: "error",
                detail: "Please enter city.",
                life: 3000,
              });
              return isValid=false
        }else if(state == null || state == undefined || state ==""){
            toast.current.show({
                severity: "error",
                detail: "Please enter state.",
                life: 3000,
              });
              return isValid=false
        

            }else if(zipCode == null || zipCode == undefined || zipCode ==""){
            toast.current.show({
                severity: "error",
                detail: "Please enter zipCode.",
                life: 3000,
              });
              return isValid=false
        }
        else{
        return isValid=true
    }
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="fenris-history-main-page">
                <Header pagename="fenris-landing-page" />
                <div className="content-wrapper ">
                    <div className="row  wrapper-main-row mx-auto wrapper-below-div">
                        <div className="col-12">
                            <FenrisMiddleHeader />
                        </div>
                        <div className="row main-content-row mx-auto">
                            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                                <FenrisSidebar pagename="fenris-landing-page" />
                            </div>
                            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                                <div className="row client-search-row d-flex align-items-center">
                                    <div className="col-2 my-1 ">
                                        <span>
                                            {" "}
                                            <AtkButton label="Back" onClick={onBackClick} className="policy-back-btn w-auto  me-3" />
                                        </span>
                                    </div>

                                    <div className="form-group col-lg-10 col-md-10 col-sm-12 mb-2">
                                        <div className="row w-100 d-flex align-items-center justify-content-end">
                                            <div className="col-7"></div>
                                            <div className="col-3 text-end">
                                                <label>
                                                    <b>Current Balance</b>
                                                </label>
                                            </div>
                                            <div className="col-2">
                                                {currentBalanceLoading ? (
                                                    <AtkLoader />
                                                ) : (
                                                    <div className="input-group">
                                                        <span className="input-group-text">$</span>
                                                        <AtkTextField className="input-field form-control p-0" type="text" id="text" value={currentBalance} isDisabled={true} />
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2"></div>
                                    <div className="col-12 divider"></div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-12 mb-2">
                                        <AtkTextField
                                            className="input-field"
                                            type="text"
                                            id="text"
                                            placeholder="Enter business or client name"
                                            onChange={(e: any) => {
                                                onChangeClientName(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-12 mb-2">
                                        <AtkTextField
                                            className="input-field"
                                            type="text"
                                            id="text"
                                            placeholder="Enter address line 1"
                                            onChange={(e: any) => {
                                                onChangeAddressLine1(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-12 mb-2">
                                        <AtkTextField
                                            className="input-field"
                                            type="text"
                                            id="text"
                                            placeholder="Enter city"
                                            onChange={(e: any) => {
                                                onChangeCity(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-12 mb-2">
                                        <select
                                            style={{
                                                width: "102%",
                                                color: "#5c5a5a",
                                                fontWeight: "500",
                                                height: "34px",
                                            }}
                                            className=""
                                            value={state}
                                            onChange={(e: any) => {
                                                onChangeState(e.target.value);
                                            }}
                                        >
                                            <option value="" selected>
                                                Select state
                                            </option>

                                            {Allstate.map((state: any) => (
                                                <option key={state.code} value={state.code}>
                                                    {state.code + "-" + state.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-12 mb-2">
                                        <AtkTextField
                                            className="input-field"
                                            type="text"
                                            id="text"
                                            placeholder="Enter zip code"
                                            onChange={(e: any) => {
                                                onChangeZipCode(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-12 mb-2">
                                        <AtkButton label="Enrich Rating Data" className="pf-secondary-btn w-auto" onClick={() => onClickEnrichButton()} />
                                    </div>
                                </div>
                                <div className="row p-2 mx-auto">
                                    {enrichButtonLoading ? 
                                    <>
                                    <div className="d-flex justify-content-center align-items-center p-1">
                                    <AtkLoader></AtkLoader>{" "}
                                  </div>
                                    </>
                                    :
                                    <>
                                    {showExistingAndFenrisData ? (
                                        <div className="col-12">
                                            <ExistingAndFenrisData
                                                insuredId={insuredId}
                                                enrichDataFlag={enrichDataFlag}
                                                updateCurrentBalanceUpdateFlag={updateCurrentBalanceUpdateFlag}
                                                balanceCheck={balanceCheck}
                                            />
                                        </div>
                                    ) : null}
                                    </>
                                     }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}