
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column";
export default function CoverageLimitDetails(props:any) {
 console.log(props.coveragelimitResponse)
  return (
    <>
        <div className="row">
      <div className="col-12  history-table mt-3">
         
                <DataTable
                      value={props.coveragelimitResponse}
                      paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink" rows={15}
                    >
                       <Column header="Bodily Injury & Property Damage Limit (CSL)" field="Bodily Injury & Property Damage Limit (CSL)"></Column>
                       <Column header="Medical Payments Limit" field="Medical Payments Limit" ></Column>
                       <Column header="UIM PD Limit" field="UIM PD Limit" ></Column>
                       <Column header="UM BI Limit" field="UM BI Limit"></Column>
                       <Column header="UM PD Limit" field="UM PD Limit"></Column>
                       <Column header="UIM BI Limit" field="UIM BI Limit"></Column>
                       <Column header="Covered Autos Symbol Liability" field="Covered Autos Symbol Liability"></Column>
                       <Column header="Covered Autos Symbol Auto Medical Payments" field="Covered Autos Symbol Auto Medical Payments"></Column>
                       <Column header="Covered Autos Symbol P. I. P." field="Covered Autos Symbol P. I. P."></Column>
                       <Column header="Covered Autos Symbol UM and/or UIM" field="Covered Autos Symbol UM and/or UIM"></Column>
                       <Column header="Personal Injury Protection Limits" field="Personal Injury Protection Limits"></Column>
                       <Column header="UIIA" field="UIIA"></Column>
                       <Column header="Deductible" field="Deductible"></Column>
                      </DataTable>
                  
              </div>
              </div>


              {/* <table className="  history-details-font ">
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Bodily Injury & Property Damage Limit (CSL)</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        Value
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Medical Payments Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        Value
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        Value
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        Value
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center fw-bold ">
                        <span>Limit</span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-6 py-2 border-bottom d-flex align-items-center">
                        Value
                      </div>
                    </div>
                  </table> */}
    </>
  )
}