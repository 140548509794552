import React from 'react'
import COILogo from "../../assets/images/COI.svg";

export default function COIScanMiddleHeader() {
  return (
    <div className="row middle-header-row">
    <div className="col-12">
      <div className="coi-scan-middle-header-col active ms-2">
        <img src={COILogo} className="landing-page-img" />
        <span className="middle-header-title">Certificate of Insurance Scan</span>
      </div>
    </div>{" "}
  </div>
  )
}
