import { DataTable } from "primereact/datatable";
import moment from "moment";
import axios from "axios";
import CommonValues from "../../common/utils";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "primereact/column";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import ViewDetailsIcon from "../../assets/images/ViewDetailsIcon.png";
import PFOcrScan from "./PFSOcrScan";
import AtkButton from "../../AtlaskitControls/AtkButton";
import PFScanMiddleHeader from "./PFScanMiddleHeader";
import PFScanSidebar from "./PFScanSidebar";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Toast } from "primereact/toast";
import feedbackImg from "../../assets/images/feedback.svg";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import TextField from "@atlaskit/textfield";
import AtkTextField from "../../AtlaskitControls/AtkTextField";

export default function PFScanHistory(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [pfScanHistoryData, setPFScanHistoryData] = useState<any>([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [submissionId, setSubmissionId] = useState();
  const [isSubmissionIdFromHistory, setIsSubmissionIdFromHistory] = useState(false);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [previewFileLoading, setPreviewFileLoading] = useState(false);
  const [extractedData, setExtractedData] = useState<any>([]);

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getPFScanHistory();
    if (feedbackRef.current) {
      feedbackRef.current.hide();
    }
  }, []);

  const getPFScanHistory = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/pfscanhistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setPFScanHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting PFScan history",
              life: 3000,
            });
          }
        }
        setHistoryLoading(false);
      });
  };

  const actionBodyFileName = (rowData: any) => {
    let fileName = rowData.fileName;
    return (
      <span className=" text-12" title={rowData.fileName}>
        {fileName.substring(0, 65) + "..."}
      </span>
    );
  };

  const OnBack = () => {
    setIsSubmissionIdFromHistory(false);
  };

  const actionTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <>
        <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="show-cursor" />
      </>
    ) : (
      <>
        <span className=" text-12 quote-not-span">Not Available</span>
      </>
    );
  };

  const onPreviewClick = (rowData: any) => {
    let responseJson = JSON.parse(rowData.responseJson);
    setExtractedData(responseJson);
    setShowPopUp(true);
    setPreviewFileLoading(true);
    const token = CommonValues.GetToken();
    var data = {
      TransactionId: rowData.submissionId,
      FileName: rowData.fileName,
      Target: "PfScan",
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setPdfUrl(response.data.fileUrl);
        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting  history details", life: 3000 });
          }
        }
        setPreviewFileLoading(false);
      });
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPdfUrl("");
  };

  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };

  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };

  const statusTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <b className="text-success"> Success</b>
    ) : rowData.status === "Failed" ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };

  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setPFScanHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.submissionId === rowData.submissionId ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setPFScanHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.submissionId === rowData.submissionId ? { ...response, isLiked: liked } : response))
    );
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setSubmissionId(rowData.submissionId);
      onSaveFeedback(rowData);
    }
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/pfscan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.submissionId,
      IsLiked: rowData.isLiked,
      Feedback: feedbackVal
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/pfscan/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Ratings Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const ShowCompanyName=(rowData:any)=>{
    if(rowData.insuranceCompanyName == "CapitalPremiumFinancing")
    {
      return (
        <span>Capital Premium Financing</span>
      )
    }
    else{
      return (
        <span>{rowData.insuranceCompanyName}</span>
      )
    }
    
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="pfscan-history-main-page">
        <Header pagename="pfscan-history" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <PFScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <PFScanSidebar pagename="pfscan-history" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  {isSubmissionIdFromHistory ? (
                    <>
                      <div className="col-12">
                        <AtkButton label="Back" onClick={OnBack} className="pf-secondary-btn w-auto mx-4" />
                      </div>
                      <div className="col-12">
                        <PFOcrScan submissionIdFromHistory={submissionId} isSubmissionIdFromHistory={isSubmissionIdFromHistory} />
                      </div>
                    </>
                  ) : (
                    <>
                      {historyLoading ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <AtkLoader />{" "}
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-12  history-table mt-3">
                            <DataTable value={pfScanHistoryData} paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink" rows={10}>
                              <Column header="File Name" field="fileName" body={actionBodyFileName}></Column>
                              <Column
                                header="Submitted Date"
                                field="created"
                                body={(rowData: any) => {
                                  if (rowData.created != null || rowData.created !== "") {
                                    return moment.utc(rowData.created, "YYYY-MM-DD").format("DD-MM-YYYY");
                                  }
                                }}
                              ></Column>
                              <Column header="Insurance Company Name" body={ShowCompanyName}></Column>
                              <Column header="Status" field="status" body={statusTemplate}></Column>
                              <Column header="Update In AMS" field="updateInAMS"></Column>
                              <Column header="Action" body={actionTemplate}></Column>
                              <Column header="Feedback" body={feedbackTemplate}></Column>
                            </DataTable>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
                  {previewFileLoading ? (
                    <div className="d-flex align-items-center justify-content-center overflow-hidden">
                      <AtkLoader />
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-6 border-end">
                        <b>Uploaded PDF</b>
                        <iframe src={pdfUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
                      </div>
                      <div className="col-6 history-table">
                        <b>Extracted data</b>
                        {Object.keys(extractedData).length > 0 ? (
                          <div className="mt-2">
                            <div className="row  my-2 mx-auto">
                              <div className="col-12">
                                <span className="text-14 ">
                                  <b>Policy Information</b>
                                </span>
                              </div>
                            </div>
                            <div className="row smartscan-block mx-auto mt-2 text-12">
                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Insured Name</label>
                                {extractedData.insuredName != null || extractedData.insuredName != undefined ?
                                  <AtkTextField id="text" className="input-field" type="text" value={extractedData.insuredName} isDisabled={true} /> :
                                  <AtkTextField id="text" className="input-field" type="text" value={extractedData.InsuredName} isDisabled={true} />
                                }
                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Policy Number</label>
                                {extractedData.policyNumber != null || extractedData.policyNumber != undefined ?

                                  <AtkTextField id="text" className="input-field " value={extractedData.policyNumber} type="text" isDisabled={true} />
                                  :
                                  <>
                                    {extractedData.policyNumber != undefined ?
                                      <AtkTextField id="text" className="input-field " value={extractedData.PolicyNumber} type="text" isDisabled={true} />
                                      :
                                      <AtkTextField id="text" className="input-field " value={extractedData.grossPremium[0].PolicyNumber} type="text" isDisabled={true} />
                                    }
                                  </>}



                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Premium Amount ($)</label>
                                <AtkTextField id="text" className="input-field " type="text" value={extractedData.TotalPremium} isDisabled={true} />
                              </div>
                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Coverage Code</label>
                                {extractedData.lineOfBusiness != null || extractedData.lineOfBusiness != null ?
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.lineOfBusiness} isDisabled={true} />
                                  : <>
                                    {extractedData.LineOfBusiness != undefined ?
                                      <AtkTextField id="text" className="input-field " type="text" value={extractedData.LineOfBusiness} isDisabled={true} />
                                      :
                                      <AtkTextField id="text" className="input-field " type="text" value={extractedData.grossPremium[0].LineOfBusiness} isDisabled={true} />
                                    }
                                  </>
                                }


                              </div>
                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Term</label>
                                {extractedData.term != null || extractedData.term != undefined ?
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.term} isDisabled={true} />
                                  : <>
                                    {extractedData.Term != undefined ?
                                      <AtkTextField id="text" className="input-field " type="text" value={extractedData.Term} isDisabled={true} />
                                      :
                                      <AtkTextField id="text" className="input-field " type="text" value={extractedData.grossPremium[0].Term} isDisabled={true} />}

                                  </>}



                              </div>
                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Carrier Name</label>
                                {extractedData.carrierName != null || extractedData.carrierName != undefined ?
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.carrierName} isDisabled={true} />
                                  : <>
                                    {extractedData.CarrierName != null ?
                                      <AtkTextField id="text" className="input-field " type="text" value={extractedData.CarrierName} isDisabled={true} />
                                      : <AtkTextField id="text" className="input-field " type="text" value={extractedData.grossPremium[0].CarrierName} isDisabled={true} />}
                                  </>}
                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                              <label>Effective Date</label>
                                {extractedData.EffectiveDate != null ?
                                  <>
                                    <AtkTextField
                                      id="text"
                                      className="input-field "
                                      type="date"
                                      value={moment(extractedData.EffectiveDate).format("YYYY-MM-DD")}
                                      isDisabled={true}
                                    />
                                  </> :
                                  <AtkTextField
                                    id="text"
                                    className="input-field "
                                    type="date"
                                    value={moment(extractedData.grossPremium[0].EffectiveDate).format("YYYY-MM-DD")}
                                    isDisabled={true}
                                  />
                                }

                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Quote Number</label>
                                <AtkTextField
                                  id="text"
                                  className="input-field "
                                  type="text"
                                  value={extractedData.QuoteNumber == null ? "" : extractedData.QuoteNumber}
                                  isDisabled={true}
                                />
                              </div>
                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Down Payment ($)</label>
                                <AtkTextField
                                  id="text"
                                  className="input-field "
                                  type="text"
                                  value={
                                    extractedData.grossPremium &&
                                      extractedData.grossPremium.length > 0 &&
                                      extractedData.grossPremium[0].DownPayment != undefined
                                      ? extractedData.grossPremium[0].DownPayment
                                      : extractedData.DownPayment !=null ? extractedData.DownPayment:0
                                  }
                                  isDisabled={true}
                                />
                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Financed Amount ($)</label>
                                <AtkTextField
                                  id="text"
                                  className="input-field "
                                  type="text"
                                  value={
                                    extractedData.grossPremium &&
                                      extractedData.grossPremium.length > 0 &&
                                      extractedData.grossPremium[0].AmountFinanced != undefined
                                      ? extractedData.grossPremium[0].AmountFinanced
                                      : extractedData.AmountFinanced !=null ? extractedData.AmountFinanced:0
                                  }
                                  isDisabled={true}
                                />
                              </div>
                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Effective APR</label>
                                <AtkTextField id="text" className="input-field " value={extractedData.EffectiveAPR} type="text" isDisabled={true} />
                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Other Taxes</label>
                                {extractedData.grossPremium[0].Taxes !=null || extractedData.grossPremium[0].Taxes !=undefined ?
                                <AtkTextField id="text" className="input-field " value={extractedData.grossPremium[0].Taxes} type="number" isDisabled={true} />:
                                <AtkTextField id="text" className="input-field " value={0} type="number" isDisabled={true} />}
                                
                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Fees</label>
                                {extractedData.grossPremium[0].Fees !=null || extractedData.grossPremium[0].Fees !=undefined ?
                                <AtkTextField id="text" className="input-field " value={extractedData.grossPremium[0].Fees} type="number" isDisabled={true} />:
                                <AtkTextField id="text" className="input-field " value={0} type="number" isDisabled={true} />}
                              </div>
                            </div>
                            <div className="row  my-2 mx-auto ">
                              <div className="col-12">
                                <span className="text-14 ">
                                  <b>Address Information</b>
                                </span>
                              </div>
                            </div>
                            <div className="row smartscan-block mx-auto mt-2 text-12">
                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Address Line 1</label>
                                {extractedData.addressLine1 != null || extractedData.addressLine1 != undefined ?
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.addressLine1} isDisabled={true} />
                                  :
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.AddressLine1} isDisabled={true} />
                                }
                              </div>
                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>City</label>
                                {extractedData.city != null || extractedData.city != undefined ?
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.city} isDisabled={true} />
                                  :
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.City} isDisabled={true} />
                                }
                              </div>
                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <span>State</span>
                                {extractedData.state != null || extractedData.state != undefined ?
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.state} isDisabled={true} />
                                  :
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.State} isDisabled={true} />
                                }

                              </div>
                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Zip Code</label>
                                {extractedData.zipCode != null || extractedData.zipCode != undefined ?
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.zipCode} isDisabled={true} />
                                  :
                                  <AtkTextField id="text" className="input-field " type="text" value={extractedData.ZipCode} isDisabled={true} />
                                }
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                </Dialog>

                <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                  <div className="row mx-auto d-flex align-items-center mb-2">
                    <div className="col-12 feedback-header">
                      <h4>Feedback</h4>
                    </div>
                    <div className={"form-group col-12 mb-2 text-12"}>
                      <textarea
                        name="feedback"
                        onChange={(e) => onFeedbackChange(e.target.value)}
                        value={feedBackData.feedback}
                        className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                        rows={4}
                        placeholder="Enter your feedback here"
                      />
                      <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                    </div>
                    <div className="col-4 d-flex justify-content-evenly">
                      <i
                        className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
                        aria-hidden="true"
                        onClick={(e) => onThumbsUpClick(feedBackData)}
                      ></i>
                      <i
                        className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
                        aria-hidden="true"
                        onClick={(e) => onThumbDownClick(feedBackData)}
                      ></i>
                    </div>
                    <div className="col-4 text-end p-2 pe-0  ">
                      <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                    </div>
                    <div className="col-4 text-end p-2  ">
                      {submissionId == feedBackData.submissionId && feedbackLoading ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <AtkLoader />
                        </div>
                      ) : (
                        <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
                      )}
                    </div>
                  </div>
                </OverlayPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
