import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ViewDetailsIcon from "../../../src/assets/images/ViewDetailsIcon.png";
import CommonValues from "../../common/utils";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import ExistingAndFenrisData from "./ExistingAndFenrisData";
import FenrisSidebar from "./FenrisSidebar";
import Header from "../../common/Header";
import FenrisMiddleHeader from "./FenrisMiddleHeader";
import { Toast } from "primereact/toast";
import feedbackImg from "../../assets/images/feedback.svg";
import FenrisFeedBack from "../FeedBack/FenrisFeedback";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
export default function FenrisHistory(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [fenrishistorydata, setFenrisHistoryData] = useState<any>([]);
  const [fenrisHistoryLoading, setFenrisHistoryLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [showExistingandFenrisPage, setShowExistingandFenrisPage] = useState(false);
  const [selectRowData, setSelectRowData] = useState("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [transactionId, setTransactionId] = useState("");
  const [isLike, setIsLike] = useState(false);
  const [isDisLike, setIsDisLike] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false)

  useEffect(() => {
    getFenrisHistory();
    if (feedbackRef.current) {
      feedbackRef.current.hide();
    }
  }, []);
  useEffect(() => {

  }, [transactionId]);
  const getFenrisHistory = () => {
    setFenrisHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/dataenrich/getfenrishistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setFenrisHistoryData(response.data);
        setFenrisHistoryLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting fenris history",
              life: 3000,
            });
          }
        }
        setFenrisHistoryLoading(false);
      });
  };

  const showDetailsAction = (rowData: any) => {
    if (rowData.status == "Success" || rowData.status == "Multiple Matches Found") {
      return (
        <>
          {actionLoading ? (
            <div className="d-flex">
              <AtkLoader />{" "}
            </div>
          ) : (
            <span className="coi-download-icon">
              <img
                src={ViewDetailsIcon}
                height="20"
                width="20"
                className="me-2 show-cursor"
                title="View Details"
                onClick={() => showDetails(rowData)}
              />
            </span>
          )}
        </>
      );
    } else {
      return <span className=" text-12 quote-not-span">Not Available</span>;
    }
  };
  const feedBackPopup = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  }
  const showDetails = (rowData: any) => {
    setShowPopUp(true)
    let rowData1 = rowData;
    setSelectRowData(rowData1);
    clientNameSet(rowData1.insuredName, true, true);
    setShowExistingandFenrisPage(true);
  };
  const clientNameSet = (clientName: any, textDisbaled: boolean, enrichBuutonDisbaled: boolean) => {
    // setClientName(clientName);
    // setSearchTextBoxDisbaled(textDisbaled);
    // setEnrichDataDisbaled(enrichBuutonDisbaled);
  };
  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setIsLike(rowData.isLike);
    setIsDisLike(rowData.isDisLike)
    setTransactionId(rowData.transactionId);
    feedbackRef.current?.toggle(e);
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
    }
  };
  const FenrisBack = (e: any) => {
    setShowExistingandFenrisPage(false);
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="fenris-history-main-page">
        <Header pagename="fenris-history" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <FenrisMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <FenrisSidebar pagename="fenris-history" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">

                  {fenrisHistoryLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <AtkLoader />{" "}
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-12  history-table mt-3">
                        <DataTable
                          value={fenrishistorydata}
                          paginator
                          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                          rows={10}
                          dataKey="id"
                        >
                          <Column header="Insured Name" field="insuredName"></Column>
                          <Column
                            header="Submitted Date"
                            field="created"
                            body={(rowData: any) => {
                              if (rowData.created != null) {
                                return moment.utc(rowData.created, "YYYY-MM-DD").format("DD-MM-YYYY");
                              }
                            }}
                            sortable
                          ></Column>
                          <Column header="Status" field="status"></Column>
                          <Column header="Update In AMS" field="updatedInAMS"></Column>
                          <Column header="Preview" body={showDetailsAction}></Column>
                          <Column
                            header="Feedback"
                            body={feedBackPopup}
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                <FenrisFeedBack
                  transcationId={transactionId}
                  onHideFeedbackPopup={onHideFeedbackPopup}
                  isLike={isLike}
                  isDisLike={isDisLike}
                />
              </OverlayPanel>

              <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
              <ExistingAndFenrisData
                      selectedData={selectRowData}
                      FenrisBack={FenrisBack}
                      fromHistoryPage="fromHistoryPage"
                    ></ExistingAndFenrisData>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
