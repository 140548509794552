import axios from "axios";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import CommonValues from "../../common/utils";

export default function FenrisFeedBack(
  props: any
) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [errorMessageForComment, setErrorMessageForComment] = useState("");
  const [thumbsLike, setThumbsLike] = useState(props.isLike);
  const [thumbsDisLike, setThumbsDisLike] = useState(props.isDisLike);
  const [feedbackMessage, setFeedBackMessage] = useState("");

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      navigate("/");
      CommonValues.Logout(navigate);
    }
  }, []);

  const onCancelClick = () => {
    props.onHideFeedbackPopup(false);
  };

  const onUpThumbs = () => {
    if (thumbsDisLike == true) {
      setThumbsDisLike(false);
    }
    setThumbsLike(!thumbsLike);
    let like = !thumbsLike;
    localStorage.setItem("like", "true");
    localStorage.setItem("dislike", "false");
    onSubmitLikeDislike(like, false);
  };

  const onDownThumbs = () => {
    if (thumbsLike == true) {
      setThumbsLike(false);
    }
    setThumbsDisLike(!thumbsDisLike);
    let dislike = !thumbsDisLike;
    localStorage.setItem("dislike", "true");
    localStorage.setItem("like", "false");
    onSubmitLikeDislike(false, dislike);
  };

  const onFeedbackSubmitClick = () => {
    if (checkValidation()) {
      setFeedbackLoading(true);
      const token = CommonValues.GetToken();
      var data = {
        feedbackMessage: feedbackMessage,
      };
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_DataFabric_Url}/dataenrich/fenrisfeedback?transactionId=${props.transcationId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          toast.current.show({
            severity: "success",
            detail: "Feedback submitted successfully.",
            life: 3000,
          });
          setFeedbackLoading(false);
          setTimeout(() => {
            props.onHideFeedbackPopup(false);
            localStorage.removeItem("like");
            localStorage.removeItem("dislike");
          }, 1000);

        })
        .catch((error: any) => {
          console.log(error);
          if (error.response != null) {
            if (error.response.status === 401) {
              CommonValues.Logout(navigate);
            } else {
              toast.current.show({
                severity: "error",
                detail: "Unknown error while inserting feedback.",
                life: 3000,
              });
            }
          }
          setFeedbackLoading(false);
        });
    }
  };

  const onSubmitLikeDislike = (like: any, dislike: any) => {
    const token = CommonValues.GetToken();
    var data = {
      like: like,
      disLike: dislike,
      feedbackMessage: feedbackMessage
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/dataenrich/likedislike?transactionId=${props.transcationId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        toast.current.show({
          severity: "success",
          detail: "Feedback submitted successfully.",
          life: 3000,
        });
        setTimeout(() => {
          props.onHideFeedbackPopup(false);
          localStorage.removeItem("like");
          localStorage.removeItem("dislike");
        }, 1000);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while inserting likedislike.",
              life: 3000,
            });
          }
        }
      });
  };

  const onCommentChange = (value: any) => {
    let feedBackMessage = value;
    if (feedBackMessage.length > 250) {
      setErrorMessageForComment(
        "Please provide your feedback within 250 character"
      );
    } else if (feedBackMessage.length == 0 || feedBackMessage.trim() === "") {
      setErrorMessageForComment("Please provide your feedback");
    } else if (feedBackMessage.length > 0) {
      setErrorMessageForComment("");
    }
    setFeedBackMessage(feedBackMessage);
  };

  const checkValidation = () => {
    let formIsValid = true;
    setErrorMessageForComment("");

    if (feedbackMessage == null || feedbackMessage.trim() === "") {
      formIsValid = false;
      setErrorMessageForComment("Please provide your feedback");
    }
    if (feedbackMessage.length > 250) {
      formIsValid = false;
      setErrorMessageForComment(
        "Please provide your feedback within 250 character"
      );
    }
    return formIsValid;
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="row mx-auto d-flex align-items-center mb-2">
        <div className="col-12 feedback-header">
          <h4>Feedback</h4>
        </div>
        <div className={"form-group col-12 mb-2 text-12"}>
          <textarea
            name="feedback"
            onChange={(e) => {
              onCommentChange(e.target.value);
            }}
            value={feedbackMessage}
            className={`form-control comment-text ${errorMessageForComment.length > 0 ? " validation-div " : ""}`}
            rows={4}
            placeholder="Enter your feedback here"
          />
          <span className="text-danger  text-12 mt-1">{errorMessageForComment}</span>
        </div>
        <div className="col-4 d-flex justify-content-evenly">
          <i className={
            thumbsLike == true
              ? "fa fa-thumbs-up feedback-thumbs-up active"
              : "fa fa-thumbs-up feedback-thumbs-up"
          }
            aria-hidden="true"
            onClick={() => onUpThumbs()}></i>
          <i
            className={
              thumbsDisLike == true
                ? "fa fa-thumbs-down feedback-thumbs-down active"
                : "fa fa-thumbs-down feedback-thumbs-down"
            }
            aria-hidden="true"
            onClick={() => onDownThumbs()}
          >
          </i>
        </div>
        <div className="col-4 text-end p-2 pe-0  ">
          <AtkButton label="Cancel" onClick={onCancelClick} className="pf-secondary-btn w-auto" />
        </div>
        <div className="col-4 text-end p-2  ">
          {feedbackLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <AtkLoader />
            </div>
          ) : (
            <AtkButton label="Submit" onClick={onFeedbackSubmitClick} className="pf-secondary-btn w-auto " />
          )}
        </div>
      </div>
    </div>
  );
}
