import React from 'react'
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Header from '../../common/Header';
import MTMScanMiddleHeader from './MTMScanMiddleHeader';
import MTMScanSidebar from './MTMScanSidebar';
export default function MTMScanHistory() {
  const toast: any = useRef()
  return (
    <>
      <Toast ref={toast} />
      <div className="smart-scan-history-main-page">
        <Header pagename="smart-scan-history" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <MTMScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <MTMScanSidebar pagename="mtm-scan-history" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                    <div className='col-4'></div>
                    <div className='col-4'><div className='coming-soon-block coming-soon-title'><span>Coming Soon</span></div></div>
                    <div className='col-4'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
} 