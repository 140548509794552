import React from 'react'
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Footer from '../common/Footer';
import AtkLoader from '../AtlaskitControls/AtkLoader';
import AtkTextField from '../AtlaskitControls/AtkTextField';
import CommonValues from "../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import Header from '../common/Header';
import RabbitSignMiddleHeader from './RabbitSignMiddleHeader';
import RabbitSignSidebar from './RabbitSignSidebar';
import AtkButtonIconBefore from '../AtlaskitControls/AtkButtonIconBefore';
import { InputSwitch } from 'primereact/inputswitch';
import ReactPlayer from 'react-player';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';


export default function RabbitSign() {
    const navigate = useNavigate();
    const toast: any = useRef("");

    const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [insufficientBalance, setInsufficientBalance] = useState(true);
    const [name, setName] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [secretKey, setSecretKey] = useState("");
    const [selectedAgent, setSelectedAgent] = useState<any>([]);
    const [dataList, setDataList] = useState([]);
    const [rabbitSignSubscribeLoading, setRabbitSignSubscribeLoading] = useState(false);
    const [isRabbitSignSubscribed, setIsRabbitSignSubscribed] = useState(false);
    const [agencyInfoList, setAgencyInfoList] = useState<any>([]);
    const [onSaveLoading, setOnSaveLoading] = useState(false);
    const [onHistoryLoading, setOnHistoryLoading] = useState(false);
    const [selectedAgencyInfoList, setSelectedAgencyInfoList] = useState<any>([]);
    const [transactionId, setTransactionId] = useState("");
    const [showUpdateButton, setShowUpdateButton] = useState(false);

    useEffect(() => {
        const token = CommonValues.GetToken();
        if (token == "" || token == null) {
            CommonValues.Logout(navigate);
        }
        getCardDetails();
        getAgencyInfoList();
        getRabbitSignHistory();
    }, []);

    const getCardDetails = () => {
        setCurrentBalanceLoading(true);
        const token = CommonValues.GetToken();
        const config = {
            method: "get",
            url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
                if (response.data.success) {
                    setCurrentBalance(response.data.currentBalance / 100);
                    setInsufficientBalance(response.data.currentBalance / 100 <= 0);
                }
                setCurrentBalanceLoading(false);
            })
            .catch((error) => {
                setCurrentBalanceLoading(false);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while getting current balance",
                            life: 3000,
                        });
                    }
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Unknown error while getting current balance",
                        life: 3000,
                    });
                }
            });
    };
    const onApiKeyChanged = (e: any) => {
        setApiKey(e.target.value)
    }
    const onSecretKeyChanged = (e: any) => {
        setSecretKey(e.target.value)
    }
    const onNameChanged = (e: any) => {
        setName(e.target.value)
    }
    const onSubscribeClicked = (e: any) => {
        setIsRabbitSignSubscribed(!isRabbitSignSubscribed)
    };
    const agentsDropdownClicked = (e: any) => {
        let agentsInfo: any = e.target.value
        setSelectedAgent(e.target.value);
        setSelectedAgencyInfoList(agentsInfo);
    };

    const OnEdit = (rowData: any) => {
        setShowUpdateButton(true);
        setTransactionId(rowData.transactionId);
        setName(rowData.name);
        setApiKey(rowData.apiKey);
        setSecretKey(rowData.secretKey);
        const agentsArray = rowData.agentInfoJson.map((item: any, index: any) => ({
            name: item.name,
            agentId: item.agentId
        }));
        setSelectedAgent(agentsArray);
    }

    const getAgencyInfoList = () => {
        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
        var config = {
            method: "GET",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/agencylist?nowCertsToken=${nowCertsToken}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
        };

        axios(config)
            .then((response) => {
                const agencyInfoList: any = [];
                response.data.value.map((item: any) => {
                    agencyInfoList.push({
                        name: item.firstName + item.lastName,
                        agentId: item.id
                    })
                })
                setAgencyInfoList(agencyInfoList);
            })
            .catch((error) => {
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        if (toast.current) {
                            toast.current.show({
                                severity: "error",
                                detail: "Unknown error while loading the agency list. Please try again later.",
                                life: 3000,
                            });
                        }
                    }
                }
            });
    };
    const getRabbitSignHistory = () => {
        setOnHistoryLoading(true)
        const token = CommonValues.GetToken();
        var config = {
            method: "get",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/RabbitSign/history`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response: any) => {
                const formattedData = response.data.responseJson.map((item: any, index: any) => {
                    const agentInfoJson = JSON.parse(item.agents);
                    return ({
                        transactionId: item.transactionId,
                        name: item.name,
                        apiKey: item.apiKey,
                        secretKey: item.secretKey,
                        agents: agentInfoJson.map((agent: any) => (agent.name)).join(', '),
                        agentInfoJson: agentInfoJson
                    })
                });
                setTimeout(() => {
                    setOnHistoryLoading(false)
                }, 2000)
                setDataList(formattedData)
            })
            .catch((error: any) => {
                console.log(error);
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({ severity: "error", detail: "Unknown error while getting rabbit sign history", life: 3000 });
                    }
                }
                setOnHistoryLoading(false)
            });
    };
    const onSaveClicked = async () => {
        setOnSaveLoading(true);
        var token = CommonValues.GetToken();
        var data = {
            Target:"RABBIT-SIGN",
            name: name,
            apiKey: apiKey,
            secretkey: secretKey,
            agents: JSON.stringify(selectedAgencyInfoList)
        }

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/RabbitSign/insert`,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then((response: any) => {
                if (response.data.status = "Success") {
                    toast.current.show({
                        severity: "success",
                        detail: "Agents credentials added successfully.",
                        life: 3000,
                    });
                    getRabbitSignHistory();
                    addInNowCertsKey();
                    setOnSaveLoading(false);
                }
                else if(!response.data.success &&
                response.data.errorMessage ===
                "User does not have enough credits for Rabbit Sign API call."
              ) {
                setOnSaveLoading(false);
                toast.current.show({
                  severity: "info",
                  detail: response.data.errorMessage,
                  life: 3000,
                });
                }
                setName("");
                setSecretKey("");
                setApiKey("")
                setSelectedAgent([]);
            })
            .catch((error: any) => {
                console.log(error);
                if (error.response.data.status == 401) {
                    CommonValues.Logout(navigate);
                } else if (toast.current) {
                    toast.current.show({
                        severity: "error",
                        detail: "Unknown error while inserting agency info. Please try again later.",
                        life: 3000,
                    });
                }
                setOnSaveLoading(false);
            });
    }
    const onDeleteAgencyInfo = (rowData: any) => {
        setTransactionId(transactionId);
        const token = CommonValues.GetToken();
        var config = {
            method: "delete",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/RabbitSign/isdelete?transactionId=${rowData.transactionId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response: any) => {
                if (response.data.status = "Success") {
                    toast.current.show({
                        severity: "success",
                        detail: "Agent credentials deleted successfully.",
                        life: 3000,
                    });
                    getRabbitSignHistory();
                }
            })
            .catch((error: any) => {
                console.log(error);
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({ severity: "error", detail: "Unknown error while delete agent credentials.", life: 3000 });
                    }
                }
            });
    };
    const onUpdateClick = () => {
        setOnSaveLoading(true);
        var token = CommonValues.GetToken();
        var data = {
            transactionId: transactionId,
            name: name,
            apiKey: apiKey,
            secretkey: secretKey,
            agents: JSON.stringify(selectedAgencyInfoList)
        }

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/RabbitSign/update`,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then((response: any) => {
                if (response.data.status = "Success") {
                    toast.current.show({
                        severity: "success",
                        detail: "Agents credentials updated successfully.",
                        life: 3000,
                    });
                    getRabbitSignHistory();
                    addInNowCertsKey();
                }
                setOnSaveLoading(false);
                setName("");
                setSecretKey("");
                setApiKey("")
                setSelectedAgent([]);
                setShowUpdateButton(false)
            })
            .catch((error: any) => {
                console.log(error);
                if (error.response.data.status == 401) {
                    CommonValues.Logout(navigate);
                } else if (toast.current) {
                    toast.current.show({
                        severity: "error",
                        detail: "Unknown error while updating agency info. Please try again later.",
                        life: 3000,
                    });
                }
                setOnSaveLoading(false);
            });
    }
    const addInNowCertsKey = () => {
        var data = {
            ApiKey: apiKey,
            secretKey: secretKey
        }
        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
        var config = {
            method: "POST",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addKey?nowCertsToken=${nowCertsToken}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
            data: data
        };

        axios(config)
            .then((response) => {
                toast.current.show({
                    severity: "success",
                    detail: "Agent key added successfully in NowCerts.",
                    life: 3000,
                });
            })
            .catch((error) => {
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        if (toast.current) {
                            toast.current.show({
                                severity: "error",
                                detail: "Unknown error while adding key in NowCerts. Please try again later.",
                                life: 3000,
                            });
                        }
                    }
                }
            });
    };
    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <i className='fa fa-pencil me-2 text-info show-cursor' onClick={() => { OnEdit(rowData) }} />
                <i className='fa fa-trash text-danger show-cursor' onClick={() => onDeleteAgencyInfo(rowData)} />
            </>
        )
    }



    return (
        <>
            <Toast ref={toast} />
            <div className="naics-landing-main-page">
                <Header pagename="naics-landing-page" />
                <div className="content-wrapper ">
                    <div className="row  wrapper-main-row mx-auto wrapper-below-div">
                        <div className="col-12">
                            <RabbitSignMiddleHeader />
                        </div>
                        <div className="row main-content-row mx-auto">
                            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                                <RabbitSignSidebar pagename="rabbitsign-page" />
                            </div>
                            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                                <div className="row history-search-row d-flex  align-items-center text-12 position-relative">
                                    <div className="col-7"></div>

                                    <div className="col-3 text-end">
                                        <label>
                                            <b>Current Balance</b>
                                        </label>
                                    </div>
                                    <div className="col-2">
                                        {currentBalanceLoading ? (
                                            <div className="d-flex align-items-center  justify-content-center p-2 ">
                                                <AtkLoader />
                                            </div>
                                        ) : (
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <AtkTextField className="input-field form-control p-0" type="text" id="text" value={currentBalance} isDisabled={true} />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="row  p-2 mt-3">
                                    <div className="col-3 ">
                                        <div className="card  subscription-card">
                                            <div className="card-body">
                                                <div className="row  d-flex  align-items-center ">
                                                    <div className="col-6 ">
                                                        <b className="fs-5">Rabbit Sign</b>
                                                    </div>
                                                    <div className="col-6 d-flex  justify-content-end ">
                                                        {rabbitSignSubscribeLoading ? (
                                                            <div className="d-flex align-items-center justify-content-center my-2">
                                                                <AtkLoader />
                                                            </div>
                                                        ) : (
                                                            <InputSwitch className="d-flex align-items-center" checked={isRabbitSignSubscribed} onChange={onSubscribeClicked} />
                                                        )}
                                                    </div>

                                                    <>
                                                        <div className="col-12">
                                                            <span className="text-26" style={{ color: "#0e9e82" }}>
                                                                $10/month
                                                            </span>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="text-20 ">Get Unlimited E-Signing</span>
                                                        </div>
                                                        <div className="col-12 text-14 mt-1" style={{ textAlign: "justify" }}>
                                                            <span className="">
                                                                Quickly send the same document for signature over and over. There is no limit on how many templates you can create and
                                                                use. Quickly send the same document for signature over and over. There is no limit on how many templates you can
                                                                create and use.
                                                            </span>
                                                        </div>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-9 ">
                                        <div className="card ">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12 mb-2">
                                                        <b className="fs-5">Guide</b>
                                                        <div className="col-12">
                                                            <span className="text-20">
                                                                Don't have an RabbitSign account, below are steps to setup an account.
                                                            </span>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="">
                                                                Step 1 : Visit <a href='https://www.rabbitsign.com/'>RabbitSign</a> portal to create an account.
                                                            </span>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="">
                                                                Step 2 : Once the account is been created, generate your API key and secret key in the portal.
                                                            </span>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="">
                                                                Step 3 : Update your API key & secret key and select the agents from your agency to subscribe to RabbitSign in the ToolBox.
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-1 col-md-1 col-sm-12 mb-2 d-flex align-items-center justify-content-center">
                                                        <b className="fs-5">OR</b>
                                                    </div>
                                                    <div className="form-group col-lg-5 col-md-5 col-sm-12 mb-2">
                                                        <ReactPlayer
                                                            className="react-video-dialog"
                                                            width="auto"
                                                            height="220px"
                                                            url={"https://www.youtube.com/watch?v=Yc-o92ZUc2Y&t=208s"}
                                                            controls={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row  p-2 mt-3">
                                    <div className="col-12 ">
                                        <div className="card ">
                                            <div className="card-body">
                                                <div className="row  d-flex  align-items-center ">
                                                    <div className="form-group col-lg-12 col-md-2 col-sm-12 mb-2">
                                                        <b className="fs-5">Already have a RabbitSign account? Enter your credentials here.</b>
                                                    </div>
                                                    <div className="form-group col-lg-3 col-md-3 col-sm-12 mb-2">
                                                        <label>Name</label>
                                                        <div className="input-group ">
                                                            <AtkTextField
                                                                className="input-field form-control p-0"
                                                                type="text"
                                                                id="text"
                                                                value={name}
                                                                onChange={onNameChanged}
                                                                placeholder="Enter key name"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-3 col-md-3 col-sm-12 mb-2">
                                                        <label>API Key</label>
                                                        <div className="input-group ">
                                                            <AtkTextField
                                                                className="input-field form-control p-0"
                                                                type="text"
                                                                id="text"
                                                                value={apiKey}
                                                                onChange={onApiKeyChanged}
                                                                placeholder="Enter api key"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-3 col-md-3 col-sm-12 mb-2">
                                                        <label>Secret Key</label>
                                                        <div className="input-group ">
                                                            <AtkTextField
                                                                className="input-field form-control p-0"
                                                                type="text"
                                                                id="text"
                                                                value={secretKey}
                                                                onChange={onSecretKeyChanged}
                                                                placeholder="Enter Secret key"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-3 col-md-3 col-sm-12 mb-2">
                                                        <label>Agents</label>
                                                        <div className="input-group ">
                                                            <MultiSelect
                                                                className="finance-company-dropdown input-field"
                                                                value={selectedAgent}
                                                                onChange={(e: any) => agentsDropdownClicked(e)}
                                                                options={agencyInfoList.map((item: any, index: any) => ({ label: item.name, value: item, index: index + 1 }))}
                                                                placeholder="Select agents"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        {onSaveLoading ?
                                                            <div className="d-flex justify-content-center align-items-center p-2">
                                                                <AtkLoader></AtkLoader>
                                                            </div>
                                                            :
                                                            <AtkButtonIconBefore
                                                                label={showUpdateButton ? "Update" : "Add"}
                                                                // iconBefore={<AtkIcon icon={SAveIcon} />}
                                                                className="pf-secondary-btn"
                                                                onClick={showUpdateButton ? onUpdateClick : onSaveClicked}
                                                            />
                                                        }
                                                    </div>

                                                </div>

                                                <div>
                                                    {onHistoryLoading ? (
                                                        <div className="d-flex justify-content-center align-items-center p-2">
                                                            <AtkLoader></AtkLoader>
                                                        </div>) :
                                                        <div className="row  d-flex  align-items-center history-table mt-4">
                                                            <DataTable rows={3} value={dataList} >
                                                                <Column header="Name" field="name"></Column>
                                                                <Column header="API Key" field='apiKey'></Column>
                                                                <Column header="Secret Key" field='secretKey'></Column>
                                                                <Column header="Agents" field='agents'></Column>
                                                                <Column header="Action" body={actionBodyTemplate}></Column>
                                                            </DataTable>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
